<template lang="pug">
  .create-company-form
    AppOverlayLoader(:state="loading")
    .create-company-form-container
      .create-company-form-block
        .create-company-form-row
          .create-company-form-row-label
            FormFieldLabel(
              :title="$t('ota_management.company.form.name')"
              required
            )
          .create-company-form-field
            BFormInput(
              type="text"
              v-model="company.name"
              name="company-name"
              :state="$v.company.name.$error ? false : null"
              :placeholder="$t('ota_management.company.form.name')"
            )
        .create-company-form-row
          .create-company-form-row-label
            FormFieldLabel(
              :title="$t('ota_management.company.form.code')"
              required
            )
          .create-company-form-field
            BFormInput(
              type="text"
              v-model="company.code"
              name="company-name"
              :state="$v.company.code.$error ? false : null"
              :placeholder="$t('ota_management.company.form.code')"
            )
        .create-company-form-row
          .create-company-form-row-label
            FormFieldLabel(:title="$t('ota_management.company.form.ota')")
          .create-company-form-field
            AppDropdown.price-car-classes(
              checkbox
              batch-select
              multiple
              allow-empty
              close-on-select
              :placeholder="$t('components.checkboxes_group.nothing_selected')"
              :value="selectedOtas"
              :items="availableOtas"
              @select="selectOtas"
            )
        .create-company-form-row
          .create-company-form-row-label
            FormFieldLabel(:title="$t('accounts.table.columns.organizations')")
          .create-company-form-field
            AppDropdown.price-car-classes(
              checkbox
              batch-select
              multiple
              allow-empty
              searchable
              close-on-select
              :is-item-disabled="isOrganizationDisabled"
              :value="selectedOrganizations"
              :items="organizations"
              @select="selectOrganizations"
            )
        .create-company-form-row(
          :data-cy="$t('organization_management.create_organization.fields.shared_inventory_enabled')"
        )
          .create-company-form-row-label
            FormFieldLabel(
              :title="$t('ota_management.company_list.auto_publish')"
            )
          .create-company-form-field
            AppToggle(
              v-model="company.released"
            )
        .create-company-form-row(
          :data-cy="$t('organization_management.create_organization.fields.company_system_enabled')"
        )
          .create-company-form-row-label
            FormFieldLabel(
              :title="$t('ota_management.company_list.default_markup_fee')"
            )
          .create-company-form-field
            MarkUpFee(
              :invalid="$v.company.fee.$error"
              :value="companyFee.value"
              :markup-type="companyFee.type"
              @change-markup-fee="changeCompanyMarkupFee"
            )
    .col
      .d-flex.justify-content-end
        AppSaveButton(
          title="actions.confirm"
          :disabled="loading"
          @save="saveCompany"
        )
</template>

<script>
  // misc
  import { PERCENT_MARKUP } from "@/config/constants"
  import { mapGetters } from "vuex"
  import { filter, get, map } from "lodash-es"
  import { OTAS_WITH_COMPANY_SYSTEM } from "@/config/constants"

  // store modules
  import companiesModule from "@/config/store/maestro/markups/companies"

  // mixins
  import withValidations from "@/mixins/withValidations"
  import withStoreModule from "@/mixins/withStoreModule"

  const validationsMixin = withValidations(({ required, minLength, minValue }) => ({
    company: {
      name: {
        required,
        minLength: minLength(1)
      },
      code: {
        required,
        minLength: minLength(1)
      },
      released: { required },
      fee: {
        value: {
          required,
          minValue: minValue(0)
        },
        type: { required }
      }
    }
  }))

  const companiesMixin = withStoreModule(companiesModule, {
    name: "maestroCompanies",
    readers: {
      loading: "loading",
      company: "item"
    },
    actions: {
      fetchCompany: "FETCH_ITEM",
      fetchCompanies: "FETCH_ITEMS",
      createCompany: "CREATE_ITEM",
      updateCompany: "UPDATE_ITEM"
    },
    mutations: {
      setCompany: "SET_ITEM"
    }
  })

  const defaultCompanyObject = () => ({
    name: "",
    code: "",
    ota_ids: [],
    organization_ids: [],
    released: false,
    fee: {
      value: null,
      type: PERCENT_MARKUP.id
    }
  })

  export default {
    components: {
      AppButton: () => import("@/components/elements/AppButton"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppToggle: () => import("@/components/elements/AppToggle"),
      MarkUpFee: () => import("../../../OtaManagement/CompanyList/MarkUpFee")
    },

    props: {
      companyId: {
        type: Number,
        required: false
      },
      organizations: {
        type: Array,
        default: () => []
      },
      disabledOrganizationIds: {
        type: Array,
        default: () => []
      }
    },

    mixins: [companiesMixin, validationsMixin],

    mounted() {
      if (this.isEdit) {
        this.fetchCompany(this.companyId)
      } else {
        this.setCompany(defaultCompanyObject())
      }
    },

    computed: {
      ...mapGetters(["translatedOtaList"]),

      isEdit() {
        return !!this.companyId
      },

      availableOtas({ translatedOtaList }) {
        return translatedOtaList.filter(({ rawName }) => OTAS_WITH_COMPANY_SYSTEM.includes(rawName))
      },

      companyOtaIds() {
        return get(this.company, "ota_ids", [])
      },

      companyOrganizationIds() {
        return get(this.company, "organization_ids", [])
      },

      selectedOtas() {
        return filter(this.availableOtas, ({ id }) => this.companyOtaIds.includes(id))
      },

      selectedOrganizations() {
        return filter(this.organizations, ({ id }) => this.companyOrganizationIds.includes(id))
      },

      companyFee() {
        return get(this.company, "fee", {})
      }
    },

    methods: {
      selectOtas(otas) {
        this.company.ota_ids = map(otas, "id")
      },

      isOrganizationDisabled({ id }) {
        return this.disabledOrganizationIds.includes(id)
      },

      selectOrganizations(organizations) {
        this.company.organization_ids = map(organizations, "id")
      },

      changeCompanyMarkupFee(fee) {
        this.company.fee = { ...this.company.fee, ...fee }
      },

      saveCompany() {
        this.validateAttributes()
        if (!this.isValid) {
          return
        }
        this.sendRequest().then(() => {
          this.$emit("close")
          this.fetchCompanies()
        })
      },

      async sendRequest() {
        if (this.isEdit) {
          await this.updateCompany(this.company)
        } else {
          await this.createCompany(this.company)
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .create-company
    margin-top: 50px
    color: $default-black

    .col-xxl-6
      @media (min-width: 1599px)
        flex: 0 0 50%
        max-width: 50%

    &.loading
      opacity: 0.7
      cursor: not-allowed

    &-form
      position: relative

      &-container
        display: flex
        gap: 20px

      &-row
        align-items: center
        display: flex
        margin: 0 0 20px 0
        width: 100%

        &-label
          width: 170px

        span
          font-size: 0.8rem

      &-field
        padding: 0 6px
        width: 61%

        .app-select
          padding: 6px 0

        input
          transition: all 0.1s linear
          border-color: $border-element-color
          font-size: 0.8rem

          &:focus
            background: $default-gray-light

          &.is-invalid
            border-color: $default-red
</style>
