import { configPrepare } from "@/helpers/vue-good-table"

const defaults = {
  tdClass: "companies-table-td",
  thClass: "companies-table-th",
  sortable: false
}

const i18nPrefix = "ota_management.company.table.columns"

export const columns = () =>
  configPrepare(
    [
      {
        field: "id",
        tdClass: "organizations-table-td id-field",
        sortable: true
      },
      {
        field: "name",
        sortable: true
      },
      {
        field: "code",
        sortable: true
      },
      {
        field: "ota",
        sortable: false,
        thClass: "companies-table-th expandable-field",
        tdClass: "companies-table-td expandable-field"
      },
      {
        field: "organization_names",
        sortable: true
      },
      {
        label: "",
        field: "actions",
        tdClass: "accounts-table-td text-right actions-field",
        sortable: false
      }
    ],
    {
      i18nPrefix,
      defaults
    }
  )
