import { bus } from "@/config"

/**
 *  Creates mixin for using modal window
 *
 *  @return {object} Mixin object
 *
 *  @example
 *    import MyComponent from "./MyComponent"
 *
 *    // Your component
 *    export default {
 *      ...
 *      methods: {
 *        handleOpenModal() {
 *          this.$openModal({
 *            title: "Modal Title",
 *            component: MyComponent,
 *            props: {
 *              name: "Name"
 *            },
 *            closeOnClick: true,
 *            hideHeader: false,
 *            headerLine: true,
 *            size: "default"
 *          })
 *        }
 *      }
 *    }
 */

export default {
  methods: {
    $openModal(modalOptions) {
      bus.emit("open-modal", modalOptions)
    }
  }
}
