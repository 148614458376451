import { api, i18n } from "@/config"
import { showToast } from "@/helpers/toasts"

export default ({ baseURI, exportURI }) => ({
  CREATE_ITEM: async ({ commit }, params) => {
    const response = await api.post(baseURI, params)
    const { items, meta } = response.data.data

    commit("SET_INVALID_FIELDS", [])
    commit("SET_ITEMS", items)
    commit("SET_PAGINATION_DATA", meta.pagination)
  },

  UPDATE_ITEM: async ({ commit }, params) => {
    const response = await api.put(`${baseURI}/${params.id}`, params)
    const { items, meta } = response.data.data

    commit("SET_INVALID_FIELDS", [])
    commit("SET_ITEMS", items)
    commit("SET_PAGINATION_DATA", meta.pagination)
  },

  DELETE_ITEM: async ({ commit, dispatch }, id) => {
    try {
      const response = await api.delete(`${baseURI}/${id}`)
      const { items, meta } = response.data.data

      commit("SET_ITEMS", items)
      commit("SET_PAGINATION_DATA", meta.pagination)
    } catch ({ response: { status } }) {
      if (status === 404) {
        showToast({ text: i18n.t("activerecord.errors.models.organization.not_found") })
      }
      dispatch("FETCH_ITEMS")
    }
  },

  FETCH_POST_ITEMS: async (_, id) => {
    await api.post(exportURI.replace("__id__", id))
  }
})
