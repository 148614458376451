import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import extractMutations from "./mutations"
import extractActions from "./actions"

const baseURI = "/maestro/markups/companies"

const store = new StoreItemsModule({
  baseURI: baseURI,
  presetActions: ["fetch"],
  withSorting: {
    field: "id",
    direction: "asc"
  }
})

store.mergeState({
  item: {}
})

store.mergeMutations(extractMutations())
store.mergeActions(extractActions({ baseURI }), withLoading)

export default store
