import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import extractMutations from "./mutations"
import extractActions from "./actions"

const BASE_URI = "/maestro/organizations"
const EXPORT_RESERVATIONS_URI = "/maestro/organizations/__id__/export_reservations"

const mapFilters = filters => {
  return {
    search_value: filters.searchValue
  }
}
const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch"],
  withPagination: true,
  withFilters: {
    searchValue: ""
  },
  withSorting: {
    field: "name",
    direction: "asc"
  },
  mapFilters
})

store.mergeState({
  invalidFields: []
})

const mutations = extractMutations()
store.mergeMutations(mutations)

const actions = extractActions({ baseURI: BASE_URI, exportURI: EXPORT_RESERVATIONS_URI })
store.mergeActions(actions, withLoading)

export default store
